<template>
	<div class="wrap wrap--sub wrap--lmpc">
		<!-- 헤더 -->
		<lmpc-header></lmpc-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title">LPMC 사업연혁</h1>
					<!-- <p class="page-head__route">Home<span></span>사업연혁</p> -->
				</div>
			</div>

			<div class="lmpc-section lmpc-history-section1">
                <div class="container">
                   <div class="history-list">
                       <ul>
                           <li>
                                <div class="hl-left">
                                   <div class="hl-year"><em>2016<small>~</small>2021</em></div>
                                </div>
                                <div class="hl-right">
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2016</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>TDCV PRIMA HCV Face-lift - Front-Lid 개발</li>
                                                <li>절연판재용 Sheet 개발 적용 - Chopped Strand Matt</li>
                                                <li>TDCV NLV Front-Lid 설계. 적용완료</li>
                                                <li>수처리용 판재 개발 - 콘크리트 판재 대체용</li>
                                                <li>고내열성 비닐에스터 SMC 개발 완료</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                           </li>
                           <li>
                                <div class="hl-left">
                                    <div class="hl-year"><em>2015<small>~</small>2011</em></div>
                                </div>
                                <div class="hl-right">
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2015</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>Overlay용 UV경화형 Sheet 개발 - with 애경화학</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2012</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>ISO/TS 16949 인증</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2011</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>난연덕트재 개발</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                           </li>
                           <li>
                                <div class="hl-left">
                                    <div class="hl-year">
                                        <em>2009<small>~</small>2007</em>
                                        <div class="img-wrap"><img src="/images/lmpc/sub/history/lmpc-history-img2.png" alt="이미지"></div>
                                    </div>
                                </div>
                                <div class="hl-right">
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2009</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>차세대 전동차 의자 개발 - 로템</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2008</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>TDCV PRIMA 부품 개발 , 적용</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2007</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>영국 지하철용 난연 윈도우마스크 납품</li>
                                                <li>서울지하철 난연 LPMC의자 납품</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                           </li>
                           <li>
                                <div class="hl-left">
                                    <div class="hl-year"><em>2004<small>~</small>2001</em></div>
                                </div>
                                <div class="hl-right">
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2004</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>TDCV NOVUS 부품( Front Lid & Bumper ) 개발</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2003</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>ISO 9001: 2000 NSF, QS 9000: 1998 NSF</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2001</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>상호 변경 ㈜디아이디</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                           </li>
                           <li>
                                <div class="hl-left">
                                    <div class="hl-year"><em>2000<small>~</small>1995</em></div>
                                </div>
                                <div class="hl-right">
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">2000</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>현대자동차 연구소 All Plastic Car 개발 참여</li>
                                                <li>대우 상용차 LPMC Front Lid & Radiator Grille 납품</li>
                                                <li>미국에 LPMC 공급 계약 (군수 품 및 자동차 제품)</li>
                                                <li>QS 9000 : ABS Quality Evaluation Inc.</li>
                                                <li>Volvo 지게차 엔진 후드 및 side door LPMC 적용</li>
                                                <li>고압 전선용 Cable Trough 개발 ( 고압, 난연 )</li>
                                                <li>ISO 9002 : ABS Quality Evaluation Inc</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">1997</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>Overlay용 UV경화형 Sheet 개발 - with 애경화학</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="hl-wrap">
                                        <strong class="hl-tit">1995</strong>
                                        <div class="list-style2">
                                            <ul>
                                                <li>ISO/TS 16949 인증</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                           </li>
                       </ul>
                   </div>
                </div>
			</div>
		</main>

		<!-- 푸터 -->
		<lmpc-footer></lmpc-footer>
	</div>
</template>

<script>
import LmpcHeader from "../../../components/client/inc/lmpc-header.vue";
import LmpcFooter from "../../../components/client/inc/lmpc-footer.vue";

export default{
	components: {
		LmpcHeader,
		LmpcFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
