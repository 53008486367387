var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub wrap--lmpc"
  }, [_c('lmpc-header'), _vm._m(0), _c('lmpc-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("LPMC 설비/인증현황")])])]), _c('div', {
    staticClass: "lmpc-section lmpc-facilities-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mark-box"
  }, [_c('h2', {
    staticClass: "page-title34"
  }, [_vm._v("(주)did LPMC 사업부는,")]), _c('p', {
    staticClass: "page-txt24"
  }, [_c('strong', [_vm._v("IOS/TS 16949:2009 의 품질보증 시스템을 기반으로")]), _vm._v(" "), _c('br', {
    staticClass: "pc"
  }), _vm._v("설계, 생산, 판매 및 A/S 의 전 과정에 고객만족을 최우선으로 하고 있습니다.")])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-facilities-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40"
  }, [_vm._v("설비현황 / 작업공정도")])]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/facilities/lmpc-facilities-img.png",
      "alt": "Sheet 생산>성형공정>가공공정>도장공정>조립공정"
    }
  })])])]), _c('div', {
    staticClass: "lmpc-section lmpc-facilities-section3"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40"
  }, [_vm._v("인증현황")])]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/facilities/lmpc-facilities-img2.png",
      "alt": "ISO 인증서, UL94 VO 인증, 유럽 난연성적서"
    }
  })])])])]);

}]

export { render, staticRenderFns }