<template>
	<div class="wrap wrap--sub wrap--lmpc">
		<!-- 헤더 -->
		<lmpc-header></lmpc-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title">LPMC 설비/인증현황</h1>
					<!-- <p class="page-head__route">Home<span></span>설비/인증현황</p> -->
				</div>
			</div>

			<div class="lmpc-section lmpc-facilities-section1">
                <div class="container">
                    <div class="mark-box">
                        <h2 class="page-title34">(주)did LPMC 사업부는,</h2>
                        <p class="page-txt24"><strong>IOS/TS 16949:2009 의 품질보증 시스템을 기반으로</strong> <br class="pc"/>설계, 생산, 판매 및 A/S 의 전 과정에 고객만족을 최우선으로 하고 있습니다.</p>
                    </div>
                </div>
			</div>

            <div class="lmpc-section lmpc-facilities-section2">
                <div class="container">
                    <div class="page-title-wrap"><h2 class="page-title40">설비현황 / 작업공정도</h2></div>
                    <div class="img-wrap"><img src="/images/lmpc/sub/facilities/lmpc-facilities-img.png" alt="Sheet 생산>성형공정>가공공정>도장공정>조립공정"></div>
                </div>
			</div>

            <div class="lmpc-section lmpc-facilities-section3">
                <div class="container">
                    <div class="page-title-wrap"><h2 class="page-title40">인증현황</h2></div>
                    <div class="img-wrap"><img src="/images/lmpc/sub/facilities/lmpc-facilities-img2.png" alt="ISO 인증서, UL94 VO 인증, 유럽 난연성적서"></div>
                </div>
			</div>
		</main>

		<!-- 푸터 -->
		<lmpc-footer></lmpc-footer>
	</div>
</template>

<script>
import LmpcHeader from "../../../components/client/inc/lmpc-header.vue";
import LmpcFooter from "../../../components/client/inc/lmpc-footer.vue";

export default{
	components: {
		LmpcHeader,
		LmpcFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
