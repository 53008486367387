var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub wrap--lmpc"
  }, [_c('lmpc-header'), _vm._m(0), _c('lmpc-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("LPMC")])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mark-box"
  }, [_c('h2', {
    staticClass: "page-title34"
  }, [_vm._v("(주)did LPMC 사업부는,")]), _c('p', {
    staticClass: "page-txt24"
  }, [_c('strong', [_vm._v("IOS/TS 16949:2009 및 UL(난연규격) 인증 등 국제인증을 보유 하였으며,")]), _vm._v(" "), _c('br', {
    staticClass: "pc"
  }), _vm._v("세계최고의 복합 신소재인 LPPMC 제조 기술을 영국 Scayy Bader사 로부터 도입하여, 최신설비와 개발기술력을 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("바탕으로 혁신적인 디자인과 최고의 품질로 국내 복합소재산업분야의 리더로 도약해 오고 있습니다.")])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "box-style2"
  }, [_c('div', {
    staticClass: "bs-header"
  }, [_c('h2', [_vm._v("회사개요")])]), _c('div', {
    staticClass: "bs-body"
  }, [_c('div', {
    staticClass: "list-style"
  }, [_c('ul', [_c('li', [_c('em', [_vm._v("설립일")]), _vm._v("1933년 10월")]), _c('li', [_c('em', [_vm._v("사업소재지")]), _vm._v("본사: 충북 진천군 광혜원면 죽현리 148-2"), _c('br'), _vm._v("서울사무소: 서울강남구 논현동 278-19 궁도B/D 10-13F")]), _c('li', [_c('em', [_vm._v("자본금")]), _vm._v("20억원 (매출: 600억원, 2007년 기준)")]), _c('li', [_c('em', [_vm._v("주요 생산품")]), _vm._v("LPMC / SMC : Sheet 생산"), _c('br'), _vm._v("Press 성형제품 : 상용 외장부품, 중장비, "), _c('br', {
    staticClass: "pc"
  }), _vm._v("물탱크, 철도차량 내장재 방재")])])])])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section3"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40 align-center"
  }, [_vm._v("사업목표")])]), _c('div', {
    staticClass: "icon-list"
  }, [_c('ul', [_c('li', [_c('i', {
    staticClass: "icon",
    staticStyle: {
      "background-image": "url(/images/lmpc/sub/intro/lmpc-intro-icon.png)"
    }
  }), _c('span', [_vm._v("자체 신복합 소재 개발 및 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("상용화를 통한 무한경쟁력 확보")])]), _c('li', [_c('i', {
    staticClass: "icon",
    staticStyle: {
      "background-image": "url(/images/lmpc/sub/intro/lmpc-intro-icon2.png)"
    }
  }), _c('span', [_vm._v("고부가가치 제품군 다양화를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("통한 Niche 마켓육성")])]), _c('li', [_c('i', {
    staticClass: "icon",
    staticStyle: {
      "background-image": "url(/images/lmpc/sub/intro/lmpc-intro-icon3.png)"
    }
  }), _c('span', [_vm._v("전략적 파트너쉽 구축을 통한 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("글로벌 업체로의 도약")])])])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40"
  }, [_vm._v("주요고객")])]), _c('div', {
    staticClass: "logo-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo.png",
      "alt": "BYK"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo2.png",
      "alt": "DOOSAN"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo3.png",
      "alt": "현대로템주식회사"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo4.png",
      "alt": "애경화학주식회사"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo5.png",
      "alt": "R.P.T ENG"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo6.png",
      "alt": "TATA DAEWOO"
    }
  })])]), _c('li', [_c('div', {
    staticClass: "ll-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-logo7.png",
      "alt": "SCOTT BADER"
    }
  })])])])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40 align-center"
  }, [_vm._v("기술제휴 현황")])]), _c('div', {
    staticClass: "box-list2"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("1")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("Scott Bader")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("자동차 및 중장비 부품 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(영국)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("2")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("하이닉스")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("3D 모델링 및 금형설계 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(한국)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("3")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("RPT.ENG")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("ＦRP 제조 및 설계 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(한국)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("4")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("애경화학")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("건자재용 부품 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(한국)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("5")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("BYK Chemie")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("IMG 기술 외 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(독일)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("6")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("Ashiand")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("상용차용 부품 기술 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(미국)")])])]), _c('li', [_c('div', {
    staticClass: "bl2-wrap"
  }, [_c('em', {
    staticClass: "bl2-num"
  }, [_vm._v("7")]), _c('h3', {
    staticClass: "bl2-tit"
  }, [_vm._v("DSM")]), _c('p', {
    staticClass: "bl-txt"
  }, [_vm._v("자동차관련부품 기술 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("(네덜란드)")])])]), _c('li', [_c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-img.png",
      "alt": "design id did"
    }
  })])])])])])]), _c('div', {
    staticClass: "lmpc-section lmpc-intro-section6"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40 align-center"
  }, [_vm._v("회사조직도")])]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/intro/lmpc-intro-img2.png",
      "alt": "조직도"
    }
  })])])]), _c('div', {
    staticClass: "container"
  }, [_c('hr', {
    staticClass: "hr-gray"
  })]), _c('div', {
    staticClass: "lmpc-section company-section5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "location-left"
  }, [_c('h3', [_c('span', [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })]), _vm._v("찾아오시는 길")]), _c('div', {
    staticClass: "in-box"
  }, [_c('h4', [_vm._v("DID LPMC")]), _c('p', [_vm._v("충청북도 진천군 광혜원면 죽현리 148-2번지")]), _c('p', [_c('strong', [_vm._v("TEL")]), _vm._v(" 043-536-2701")]), _c('p', [_c('strong', [_vm._v("FAX")]), _vm._v(" 043-536-1088")])])]), _c('div', {
    staticClass: "location-right"
  }, [_c('div', {
    staticClass: "map"
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12754.672883143878!2d127.41477392396571!3d36.94609478183104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3564c9018fd6ec73%3A0x477d0606e98d66d6!2z7Lap7LKt67aB64-EIOynhOyynOq1sCDqtJHtmJzsm5DrqbQg7KO97ZiE66asIDE0OC0y!5e0!3m2!1sko!2skr!4v1612940911419!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "frameborder": "0",
      "allowfullscreen": "",
      "aria-hidden": "false",
      "tabindex": "0"
    }
  })])])])])]);

}]

export { render, staticRenderFns }