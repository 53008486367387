<template>
	<div class="wrap wrap--sub wrap--lmpc">
		<!-- 헤더 -->
		<lmpc-header></lmpc-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title">LPMC 제품소개</h1>
					<!-- <p class="page-head__route">Home<span></span>제품소개</p> -->
				</div>
			</div>

			<div class="lmpc-section lmpc-product-section1">
                <div class="container">
                    <div class="img-wrap"><img src="/images/lmpc/sub/product/lmpc-product-img.png" alt="이미지"></div>
                    <div class="mark-list">
                        <ul>
                            <li>
                                <div class="ml-wrap">
                                    <h3 class="ml-tit"><strong class="color-point">SMC/LPMC </strong>Sheet</h3>
                                    <p class="page-text24">최신 독일 Schmind & Heinzmann 사의 설비로 고객 제품특성에 최적화 된 Sheet 지를 국내 유수업체에 공급하여 기술력을 인증 받았습니다.</p>
                                </div>
                            </li>
                            <li>
                                <div class="ml-wrap">
                                    <h3 class="ml-tit"><strong class="color-point">PRESS </strong>성형품</h3>
                                    <p class="page-text24">국제기준의 난연/내연/고강도 등 다목적 지향부터 High Tech 제품군까지 엄격한 품질관리 System을 통해 다국적기업에 납품해오고 있습니다.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>

            <div class="container"><hr class="hr-gray"/></div>
			
            <div class="lmpc-section lmpc-product-section2">
                <div class="container">
                    <div class="page-title-wrap"><h2 class="page-title40">Press 성형품</h2></div>
                    <div class="img-list">
                        <ul>
                            <li>
                                <div class="il-img"><img src="/images/lmpc/sub/product/lmpc-product-img2.png" alt="이미지"></div>
                                <div class="il-txt">1. 전동 HOOD</div>
                            </li>
                            <li>
                                <div class="il-img"><img src="/images/lmpc/sub/product/lmpc-product-img3.png" alt="이미지"></div>
                                <div class="il-txt">2. 디젤 HOOD</div>
                            </li>
                            <li>
                                <div class="il-img"><img src="/images/lmpc/sub/product/lmpc-product-img4.png" alt="이미지"></div>
                                <div class="il-txt">3. NOVUS FRONT LID</div>
                            </li>
                            <li>
                                <div class="il-img"><img src="/images/lmpc/sub/product/lmpc-product-img5.png" alt="이미지"></div>
                                <div class="il-txt">4. PRIMA WIPER COVER<br/>5. PRIMA FLAP CTR</div>
                            </li>
                            <li>
                                <div class="il-img"><img src="/images/lmpc/sub/product/lmpc-product-img6.png" alt="이미지"></div>
                                <div class="il-txt">6. PRIMA FRT LID<br/>7. CABIN STORAGE<br/>8.ADD ON DOOR</div>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>
		</main>

		<!-- 푸터 -->
		<lmpc-footer></lmpc-footer>
	</div>
</template>

<script>
import LmpcHeader from "../../../components/client/inc/lmpc-header.vue";
import LmpcFooter from "../../../components/client/inc/lmpc-footer.vue";

export default{
	components: {
		LmpcHeader,
		LmpcFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
