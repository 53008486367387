<template>
  <header class="header" :data-header="val">
    <div class="header__inner">
      <!-- <div class="header__top">
        <div class="header__top-inner">
          <ul class="header-customer-ul">
            <li v-if="!accessToken">
              <router-link to="/login"><span>로그인</span></router-link>
            </li>
            <li v-if="accessToken"><a href="#" @click="logout">로그아웃</a></li>
            <li v-if="!accessToken">
              <router-link to="/join"><span>회원가입</span></router-link>
            </li>
            <li v-if="accessToken">
              <router-link to="/mypage/order-list">마이페이지</router-link>
            </li>
          </ul>
          <div class="header-top-line"></div>
          <div class="header-sns-ul">
            <li>
              <a href="https://www.youtube.com/channel/UCXHOt68h8tZUMxVz2TJQ7GA"
                ><img src="/images/icon/social-y.png" alt=""
              /></a>
            </li>
            <li>
              <a href="https://blog.naver.com/did_wallpaper"
                ><img src="/images/icon/naver-blog.png" alt=""
              /></a>
            </li>
            <li>
              <a href="https://www.facebook.com/didwallpaper"
                ><img src="/images/icon/facebook.png" alt=""
              /></a>
            </li>
          </div>
        </div>
      </div> -->
      <div class="header__middle">
        <div class="header__row">
          <div class="header__row-inner">
            <div class="header-logo lmpc-logo">
              <h1>
                <a href="/lmpc/intro" title="홈으로 이동"><span class="blind">디아이디 LMPC</span></a>
              </h1>
            </div>
            <div class="header-contents">
              <ul>
                <li>
                  <a class="link" href="/lmpc/intro"><h2>사업부소개</h2></a>
                </li>
                <li>
                  <a class="link" href="/lmpc/history"><h2>사업연혁</h2></a>
                </li>
                <li>
                  <a class="link" href="/lmpc/product"><h2>제품소개</h2></a>
                </li>
                <li>
                  <a class="link" href="/lmpc/facilities"><h2>설비/인증현황</h2></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-menu">
      <button
        class="burger-menu"
        type="button"
        role="button"
        aria-pressed="false"
      >
        <span></span>
      </button>
    </div>

  </header>
</template>

<script>
import CollectionGnb from "./collection-gnb.vue";
export default {
  components: {
    CollectionGnb
  },
  props: {
    val: { type: String, default: "sticky" }
  },
  data: () => {
    return {
      searchValue: null
    }
  },
  mounted() {
    this.header();
  },
  methods: {
    onCllectionClick: function (e) {
      if (window.innerWidth < 1200) {
        e.preventDefault();
      }
    },
    header: function () {
      window.addEventListener('load', function () {
      }, false);
    },
    logout() {
      if (confirm("로그아웃 하시겠습니까?")) {
        this.$store.dispatch("logout");
      }
    },
    search() {
      this.$router.push({
        path: "/shop/search",
        query: {
          searchValue: this.searchValue
        }
      });
    }
  },
  computed: {
    accessToken() {
      return this.$store.state.accessToken;
    }
  }
}
</script>
