<template>
	<div class="wrap wrap--sub wrap--lmpc">
		<!-- 헤더 -->
		<lmpc-header></lmpc-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title">LPMC</h1>
					<!-- <p class="page-head__route">Home<span></span>사업부소개</p> -->
				</div>
			</div>

			<div class="lmpc-section lmpc-intro-section1">
                <div class="container">
                    <div class="mark-box">
                        <h2 class="page-title34">(주)did LPMC 사업부는,</h2>
                        <p class="page-txt24"><strong>IOS/TS 16949:2009 및 UL(난연규격) 인증 등 국제인증을 보유 하였으며,</strong> <br class="pc"/>세계최고의 복합 신소재인 LPPMC 제조 기술을 영국 Scayy Bader사 로부터 도입하여, 최신설비와 개발기술력을 <br class="pc"/>바탕으로 혁신적인 디자인과 최고의 품질로 국내 복합소재산업분야의 리더로 도약해 오고 있습니다.</p>
                    </div>
                </div>
			</div>
			
            <div class="lmpc-section lmpc-intro-section2">
                <div class="container">
                    <div class="box-style2">
                        <div class="bs-header">
                            <h2>회사개요</h2>
                        </div>
                        <div class="bs-body">
                            <div class="list-style">
                                <ul>
                                    <li><em>설립일</em>1933년 10월</li>
                                    <li><em>사업소재지</em>본사: 충북 진천군 광혜원면 죽현리 148-2<br/>서울사무소: 서울강남구 논현동 278-19 궁도B/D 10-13F</li>
                                    <li><em>자본금</em>20억원 (매출: 600억원, 2007년 기준)</li>
                                    <li><em>주요 생산품</em>LPMC / SMC : Sheet 생산<br/>Press 성형제품 : 상용 외장부품, 중장비, <br class="pc"/>물탱크, 철도차량 내장재 방재</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
			</div>

            <div class="lmpc-section lmpc-intro-section3">
                <div class="container">
                    <div class="page-title-wrap">
                        <h2 class="page-title40 align-center">사업목표</h2>
                    </div>
                    <div class="icon-list">
                        <ul>
                            <li><i class="icon" style="background-image:url(/images/lmpc/sub/intro/lmpc-intro-icon.png);"></i><span>자체 신복합 소재 개발 및 <br class="pc"/>상용화를 통한 무한경쟁력 확보</span></li>
                            <li><i class="icon" style="background-image:url(/images/lmpc/sub/intro/lmpc-intro-icon2.png);"></i><span>고부가가치 제품군 다양화를 <br class="pc"/>통한 Niche 마켓육성</span></li>
                            <li><i class="icon" style="background-image:url(/images/lmpc/sub/intro/lmpc-intro-icon3.png);"></i><span>전략적 파트너쉽 구축을 통한 <br class="pc"/>글로벌 업체로의 도약</span></li>
                        </ul>
                    </div>
                </div>
			</div>

            <div class="lmpc-section lmpc-intro-section4">
                <div class="container">
                    <div class="page-title-wrap">
                        <h2 class="page-title40">주요고객</h2>
                    </div>
                    <div class="logo-list">
                        <ul>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo.png" alt="BYK"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo2.png" alt="DOOSAN"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo3.png" alt="현대로템주식회사"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo4.png" alt="애경화학주식회사"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo5.png" alt="R.P.T ENG"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo6.png" alt="TATA DAEWOO"></div></li>
                            <li><div class="ll-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-logo7.png" alt="SCOTT BADER"></div></li>
                        </ul>
                    </div>
                </div>
			</div>

            <div class="lmpc-section lmpc-intro-section5">
                <div class="container">
                    <div class="page-title-wrap">
                        <h2 class="page-title40 align-center">기술제휴 현황</h2>
                    </div>
                    <div class="box-list2">
                        <ul>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">1</em>
                                    <h3 class="bl2-tit">Scott Bader</h3>
                                    <p class="bl-txt">자동차 및 중장비 부품 <br class="pc"/>(영국)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">2</em>
                                    <h3 class="bl2-tit">하이닉스</h3>
                                    <p class="bl-txt">3D 모델링 및 금형설계 <br class="pc"/>(한국)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">3</em>
                                    <h3 class="bl2-tit">RPT.ENG</h3>
                                    <p class="bl-txt">ＦRP 제조 및 설계 <br class="pc"/>(한국)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">4</em>
                                    <h3 class="bl2-tit">애경화학</h3>
                                    <p class="bl-txt">건자재용 부품 <br class="pc"/>(한국)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">5</em>
                                    <h3 class="bl2-tit">BYK Chemie</h3>
                                    <p class="bl-txt">IMG 기술 외 <br class="pc"/>(독일)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">6</em>
                                    <h3 class="bl2-tit">Ashiand</h3>
                                    <p class="bl-txt">상용차용 부품 기술 <br class="pc"/>(미국)</p>
                                </div>
                            </li>
                            <li>
                                <div class="bl2-wrap">
                                    <em class="bl2-num">7</em>
                                    <h3 class="bl2-tit">DSM</h3>
                                    <p class="bl-txt">자동차관련부품 기술 <br class="pc"/>(네덜란드)</p>
                                </div>
                            </li>
                            <li>
                                <div class="img-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-img.png" alt="design id did"></div>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>

            <div class="lmpc-section lmpc-intro-section6">
                <div class="container">
                    <div class="page-title-wrap">
                        <h2 class="page-title40 align-center">회사조직도</h2>
                    </div>
                    <div class="img-wrap"><img src="/images/lmpc/sub/intro/lmpc-intro-img2.png" alt="조직도"></div>
                </div>
			</div>

            <div class="container"><hr class="hr-gray"/></div>

			<div class="lmpc-section company-section5">
				<div class="container">
					<div class="location-left">
						<h3><span><img src="" alt=""></span>찾아오시는 길</h3>
						<div class="in-box">
							<h4>DID LPMC</h4>
							<p>충청북도 진천군 광혜원면 죽현리 148-2번지</p>
							<p><strong>TEL</strong> 043-536-2701</p>
                            <p><strong>FAX</strong> 043-536-1088</p>
						</div>
					</div>
					<div class="location-right">
						<div class="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12754.672883143878!2d127.41477392396571!3d36.94609478183104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3564c9018fd6ec73%3A0x477d0606e98d66d6!2z7Lap7LKt67aB64-EIOynhOyynOq1sCDqtJHtmJzsm5DrqbQg7KO97ZiE66asIDE0OC0y!5e0!3m2!1sko!2skr!4v1612940911419!5m2!1sko!2skr" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<lmpc-footer></lmpc-footer>
	</div>
</template>

<script>
import LmpcHeader from "../../../components/client/inc/lmpc-header.vue";
import LmpcFooter from "../../../components/client/inc/lmpc-footer.vue";

export default{
	components: {
		LmpcHeader,
		LmpcFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
