var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub wrap--lmpc"
  }, [_c('lmpc-header'), _vm._m(0), _c('lmpc-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("LPMC 사업연혁")])])]), _c('div', {
    staticClass: "lmpc-section lmpc-history-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "history-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "hl-left"
  }, [_c('div', {
    staticClass: "hl-year"
  }, [_c('em', [_vm._v("2016"), _c('small', [_vm._v("~")]), _vm._v("2021")])])]), _c('div', {
    staticClass: "hl-right"
  }, [_c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2016")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("TDCV PRIMA HCV Face-lift - Front-Lid 개발")]), _c('li', [_vm._v("절연판재용 Sheet 개발 적용 - Chopped Strand Matt")]), _c('li', [_vm._v("TDCV NLV Front-Lid 설계. 적용완료")]), _c('li', [_vm._v("수처리용 판재 개발 - 콘크리트 판재 대체용")]), _c('li', [_vm._v("고내열성 비닐에스터 SMC 개발 완료")])])])])])]), _c('li', [_c('div', {
    staticClass: "hl-left"
  }, [_c('div', {
    staticClass: "hl-year"
  }, [_c('em', [_vm._v("2015"), _c('small', [_vm._v("~")]), _vm._v("2011")])])]), _c('div', {
    staticClass: "hl-right"
  }, [_c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2015")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("Overlay용 UV경화형 Sheet 개발 - with 애경화학")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2012")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("ISO/TS 16949 인증")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2011")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("난연덕트재 개발")])])])])])]), _c('li', [_c('div', {
    staticClass: "hl-left"
  }, [_c('div', {
    staticClass: "hl-year"
  }, [_c('em', [_vm._v("2009"), _c('small', [_vm._v("~")]), _vm._v("2007")]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/history/lmpc-history-img2.png",
      "alt": "이미지"
    }
  })])])]), _c('div', {
    staticClass: "hl-right"
  }, [_c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2009")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("차세대 전동차 의자 개발 - 로템")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2008")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("TDCV PRIMA 부품 개발 , 적용")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2007")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("영국 지하철용 난연 윈도우마스크 납품")]), _c('li', [_vm._v("서울지하철 난연 LPMC의자 납품")])])])])])]), _c('li', [_c('div', {
    staticClass: "hl-left"
  }, [_c('div', {
    staticClass: "hl-year"
  }, [_c('em', [_vm._v("2004"), _c('small', [_vm._v("~")]), _vm._v("2001")])])]), _c('div', {
    staticClass: "hl-right"
  }, [_c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2004")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("TDCV NOVUS 부품( Front Lid & Bumper ) 개발")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2003")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("ISO 9001: 2000 NSF, QS 9000: 1998 NSF")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2001")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("상호 변경 ㈜디아이디")])])])])])]), _c('li', [_c('div', {
    staticClass: "hl-left"
  }, [_c('div', {
    staticClass: "hl-year"
  }, [_c('em', [_vm._v("2000"), _c('small', [_vm._v("~")]), _vm._v("1995")])])]), _c('div', {
    staticClass: "hl-right"
  }, [_c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("2000")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("현대자동차 연구소 All Plastic Car 개발 참여")]), _c('li', [_vm._v("대우 상용차 LPMC Front Lid & Radiator Grille 납품")]), _c('li', [_vm._v("미국에 LPMC 공급 계약 (군수 품 및 자동차 제품)")]), _c('li', [_vm._v("QS 9000 : ABS Quality Evaluation Inc.")]), _c('li', [_vm._v("Volvo 지게차 엔진 후드 및 side door LPMC 적용")]), _c('li', [_vm._v("고압 전선용 Cable Trough 개발 ( 고압, 난연 )")]), _c('li', [_vm._v("ISO 9002 : ABS Quality Evaluation Inc")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("1997")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("Overlay용 UV경화형 Sheet 개발 - with 애경화학")])])])]), _c('div', {
    staticClass: "hl-wrap"
  }, [_c('strong', {
    staticClass: "hl-tit"
  }, [_vm._v("1995")]), _c('div', {
    staticClass: "list-style2"
  }, [_c('ul', [_c('li', [_vm._v("ISO/TS 16949 인증")])])])])])])])])])])]);

}]

export { render, staticRenderFns }