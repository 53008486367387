var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub wrap--lmpc"
  }, [_c('lmpc-header'), _vm._m(0), _c('lmpc-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("LPMC 제품소개")])])]), _c('div', {
    staticClass: "lmpc-section lmpc-product-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "mark-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "ml-wrap"
  }, [_c('h3', {
    staticClass: "ml-tit"
  }, [_c('strong', {
    staticClass: "color-point"
  }, [_vm._v("SMC/LPMC ")]), _vm._v("Sheet")]), _c('p', {
    staticClass: "page-text24"
  }, [_vm._v("최신 독일 Schmind & Heinzmann 사의 설비로 고객 제품특성에 최적화 된 Sheet 지를 국내 유수업체에 공급하여 기술력을 인증 받았습니다.")])])]), _c('li', [_c('div', {
    staticClass: "ml-wrap"
  }, [_c('h3', {
    staticClass: "ml-tit"
  }, [_c('strong', {
    staticClass: "color-point"
  }, [_vm._v("PRESS ")]), _vm._v("성형품")]), _c('p', {
    staticClass: "page-text24"
  }, [_vm._v("국제기준의 난연/내연/고강도 등 다목적 지향부터 High Tech 제품군까지 엄격한 품질관리 System을 통해 다국적기업에 납품해오고 있습니다.")])])])])])])]), _c('div', {
    staticClass: "container"
  }, [_c('hr', {
    staticClass: "hr-gray"
  })]), _c('div', {
    staticClass: "lmpc-section lmpc-product-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h2', {
    staticClass: "page-title40"
  }, [_vm._v("Press 성형품")])]), _c('div', {
    staticClass: "img-list"
  }, [_c('ul', [_c('li', [_c('div', {
    staticClass: "il-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img2.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "il-txt"
  }, [_vm._v("1. 전동 HOOD")])]), _c('li', [_c('div', {
    staticClass: "il-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img3.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "il-txt"
  }, [_vm._v("2. 디젤 HOOD")])]), _c('li', [_c('div', {
    staticClass: "il-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img4.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "il-txt"
  }, [_vm._v("3. NOVUS FRONT LID")])]), _c('li', [_c('div', {
    staticClass: "il-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img5.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "il-txt"
  }, [_vm._v("4. PRIMA WIPER COVER"), _c('br'), _vm._v("5. PRIMA FLAP CTR")])]), _c('li', [_c('div', {
    staticClass: "il-img"
  }, [_c('img', {
    attrs: {
      "src": "/images/lmpc/sub/product/lmpc-product-img6.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "il-txt"
  }, [_vm._v("6. PRIMA FRT LID"), _c('br'), _vm._v("7. CABIN STORAGE"), _c('br'), _vm._v("8.ADD ON DOOR")])])])])])])]);

}]

export { render, staticRenderFns }