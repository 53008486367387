var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header",
    attrs: {
      "data-header": _vm.val
    }
  }, [_vm._m(0), _vm._m(1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header__middle"
  }, [_c('div', {
    staticClass: "header__row"
  }, [_c('div', {
    staticClass: "header__row-inner"
  }, [_c('div', {
    staticClass: "header-logo lmpc-logo"
  }, [_c('h1', [_c('a', {
    attrs: {
      "href": "/lmpc/intro",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("디아이디 LMPC")])])])]), _c('div', {
    staticClass: "header-contents"
  }, [_c('ul', [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/lmpc/intro"
    }
  }, [_c('h2', [_vm._v("사업부소개")])])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/lmpc/history"
    }
  }, [_c('h2', [_vm._v("사업연혁")])])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/lmpc/product"
    }
  }, [_c('h2', [_vm._v("제품소개")])])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/lmpc/facilities"
    }
  }, [_c('h2', [_vm._v("설비/인증현황")])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-menu"
  }, [_c('button', {
    staticClass: "burger-menu",
    attrs: {
      "type": "button",
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('span')])]);

}]

export { render, staticRenderFns }